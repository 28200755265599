import { useRouter } from 'next/router'
import useSWR, { SWRConfiguration } from 'swr'
import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { PostInterface } from '../types/post-interface'

interface usePinnedPostsOptions {
  config?: SWRConfiguration<PostInterface[], Error>
}

export const getPinnedPostsKey = (
  communityPath: string,
  topicPath?: string | undefined,
): string | null => {
  if (!communityPath) return null

  return !topicPath && communityPath
    ? `${COMMUNITY_API_PREFIX}/${communityPath}/posts/pinned`
    : `${COMMUNITY_API_PREFIX}/${communityPath}/topic/${topicPath}/posts/pinned`
}

export function usePinnedPosts({ config }: usePinnedPostsOptions) {
  const router = useRouter()
  const { user } = useUser()
  const { communityPath, topicPath } = router.query
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, PostInterface[]>({
    method: RequestMethodsEnum.get,
    badRequestHandler: () => {},
  })

  return useSWR<PostInterface[], Error>(
    () => {
      if (user) {
        return getPinnedPostsKey(communityPath as string, topicPath as string | undefined)
      }
      return null
    },
    fetcher,
    config,
  )
}
