import { isDomainOwner, isWsAdmin, isWsAssistant } from 'shared/hooks/use-user'
import { UserInterface } from 'shared/types/user-interface'
import { ABACRole } from 'modules/community/utils/abac-roles-permissions'

export const determineRole = (user: UserInterface): ABACRole => {
  switch (true) {
    case isWsAdmin(user):
      return 'admin'
    case isWsAssistant(user):
      return 'assistant'
    case isDomainOwner(user):
      return 'owner'
    default:
      return 'member'
  }
}
