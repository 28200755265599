export const PostAnnounceSkeleton = () => (
  <div className="mb-10 animate-pulse overflow-hidden rounded-lg bg-white">
    <div className="w-full px-5 py-5 lg:px-16 lg:py-10">
      <div className="mb-4 flex items-center gap-2">
        <div className="h-6 w-6 rounded-full bg-gray" />
        <div className="h-4 w-14 rounded-sm bg-gray" />
        <div className="h-4 w-14 rounded-sm bg-gray" />
      </div>
      <div className="mb-2 h-7 rounded-sm bg-gray" />
      <div className="mb-5 h-7 w-3/4 rounded-sm bg-gray" />
      {Array.from(Array(6).keys()).map(index => (
        <div className="mb-3 h-3 rounded-sm bg-gray" key={index} />
      ))}
    </div>
    <hr className="mx-5 px-5 text-gray lg:mx-0" />
    <div className="flex w-full justify-between px-5 py-5 lg:px-16 lg:py-10">
      <div className="flex gap-4">
        <div className="flex items-center gap-2">
          <div className="h-6 w-6 rounded-full bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
        </div>
        <div className="flex items-center gap-2">
          <div className="h-6 w-6 rounded-full bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
        </div>
      </div>
      <div className="">
        <div className="h-6 w-20 rounded-sm bg-gray" />
      </div>
    </div>
  </div>
)
