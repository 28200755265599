import { ComponentProps } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import AudioUploader from 'shared/components/audio-uploader'
import { fileInputAcceptTypes } from 'shared/utils/file-input-accept-types'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'
import useUploadFile from 'modules/attachments/hooks/use-upload-datafile'
import {
  AttachmentTypeEnum,
  AttachmentUploadStatusEnum,
} from 'modules/community/types/post-attachment'

interface UploadAttachmentProps extends ComponentProps<'input'> {
  className?: string
  iconClassName?: string
  acceptTypes?: string[]
}

const AUDIO_SIZE_LIMIT_MB = 15
const AUDIO_SIZE_LIMIT_BYTES = AUDIO_SIZE_LIMIT_MB * Math.pow(10, 6)

const defaultAcceptTypes = fileInputAcceptTypes.audioExtensions

export const AudioAttachement = ({
  className,
  iconClassName,
  acceptTypes = defaultAcceptTypes,
}: UploadAttachmentProps) => {
  const { t } = useTranslation()
  const { addError, addAttachment, updateAttachment } = usePostAttachmentsContext()
  const { validateAndGetUploadOptions, uploadFile, activateFile } = useUploadFile()

  const handleAudioUpload = async (file: File) => {
    if (file.size > AUDIO_SIZE_LIMIT_BYTES) {
      toast.error(
        t('post.attachments.file_upload.size_limit_reached', { size: `${AUDIO_SIZE_LIMIT_MB}MB` }),
      )
      return
    }

    const attachmentId = addAttachment({
      id: crypto.randomUUID(),
      status: AttachmentUploadStatusEnum.Uploading,
      type: AttachmentTypeEnum.Audio,
    })

    try {
      const { id, uploadOptions } = await validateAndGetUploadOptions({
        file,
      })
      await uploadFile({ file, uploadOptions })
      const activatedFile = await activateFile(id)

      updateAttachment(attachmentId, {
        name: activatedFile[id].name,
        dataFileId: id,
        status: AttachmentUploadStatusEnum.Success,
        url: activatedFile[id].path,
      })
    } catch {
      addError(attachmentId, t('post.attachments.upload_failed'))
    }
  }

  const handleSelectFile = async (file: File) => {
    if (fileInputAcceptTypes.audioExtensions.includes(file.type)) {
      handleAudioUpload(file)
    } else {
      toast.error(t('post.attachments.file_upload.unsupported_type'))
    }
  }

  return (
    <AudioUploader
      className={className}
      iconClassName={iconClassName}
      onSelect={handleSelectFile}
      onError={error => toast.error(error)}
      acceptTypes={acceptTypes}
    />
  )
}
