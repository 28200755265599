import { useEffect, useRef, useState } from 'react'
import { twJoin } from 'tailwind-merge'
import { CarouselButton } from 'shared/components/carousel-button'
import { useMediaQuery } from 'shared/hooks/use-match-media'
import { LastPreviewSlide } from 'modules/attachments/components/preview/last-preview-slide'
import AttachmentPreviewItem from 'modules/attachments/components/preview/preview-item'
import { filterNotGalleryAttachments } from 'modules/attachments/utils/attachment-utils'
import { PostAttachmentType } from 'modules/community/types/post-attachment'

interface PostAttachmentsViewerProps {
  attachments: PostAttachmentType[]
  className?: string
}

export const PostAttachmentsViewer: React.FC<PostAttachmentsViewerProps> = ({
  attachments,
  className,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)', false)
  const [scrollState, setScrollState] = useState({ canScrollLeft: false, canScrollRight: false })

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = scrollContainerRef.current
    if (!el) return

    const updateScrollState = () => {
      setScrollState({
        canScrollLeft: el.scrollLeft > 0,
        canScrollRight: el.scrollLeft + el.clientWidth < el.scrollWidth,
      })
    }

    updateScrollState()
    el.addEventListener('scroll', updateScrollState)
    window.addEventListener('resize', updateScrollState)
    return () => {
      el.removeEventListener('scroll', updateScrollState)
      window.removeEventListener('resize', updateScrollState)
    }
  }, [attachments])

  const scrollBy = (distance: number) => {
    scrollContainerRef.current?.scrollBy({ left: distance, behavior: 'smooth' })
  }

  const handleScrollLeft = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    scrollBy(-250)
  }

  const handleScrollRight = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    scrollBy(250)
  }

  const nonAudioAttachments = filterNotGalleryAttachments(attachments)
  const displayedAttachments = isMobile ? nonAudioAttachments : nonAudioAttachments.slice(0, 3)

  if (!nonAudioAttachments.length) return null

  return (
    <div className={twJoin('relative', className)}>
      {isMobile && scrollState.canScrollLeft && (
        <CarouselButton onClick={handleScrollLeft} direction="left" />
      )}
      {isMobile && scrollState.canScrollRight && (
        <CarouselButton onClick={handleScrollRight} direction="right" />
      )}

      <div
        className="max-md:hidden-scrollbar mt-8 flex flex-row gap-3 max-md:max-w-full max-md:overflow-auto"
        ref={scrollContainerRef}
      >
        {displayedAttachments?.map((attachment, index) => {
          const isLastPreviewOverlay =
            !isMobile && nonAudioAttachments.length > 3 && index === displayedAttachments.length - 1

          return (
            <div key={attachment.dataFileId} className="relative md:size-auto md:basis-1/3">
              <AttachmentPreviewItem
                className="h-64 w-64 max-w-full md:size-auto"
                attachment={attachment}
                size="original"
              />
              {isLastPreviewOverlay && (
                <LastPreviewSlide
                  index={index}
                  slidesCount={nonAudioAttachments.length}
                  visibleSlidesCount={displayedAttachments.length}
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
