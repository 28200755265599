import React from 'react'

function MicrophoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.3965 11.9567C22.3965 11.5427 22.0609 11.2073 21.647 11.2073C21.2332 11.2073 20.8976 11.5427 20.8976 11.9567C20.8976 16.0512 17.5664 19.3824 13.4719 19.3824C9.37741 19.3824 6.04623 16.0511 6.04623 11.9567C6.04623 11.5427 5.71068 11.2073 5.2968 11.2073C4.88291 11.2073 4.54736 11.5427 4.54736 11.9567C4.54736 16.6254 8.15084 20.4676 12.7225 20.8495V23.5011H9.38431C8.97042 23.5011 8.63487 23.8365 8.63487 24.2505C8.63487 24.6645 8.97042 24.9999 9.38431 24.9999H17.5594C17.9733 24.9999 18.3089 24.6645 18.3089 24.2505C18.3089 23.8365 17.9733 23.5011 17.5594 23.5011H14.2212V20.8495C18.7929 20.4676 22.3965 16.6254 22.3965 11.9567Z"
        fill="currentColor"
      />
      <path
        d="M13.4717 16.7937C16.1389 16.7937 18.3088 14.6238 18.3088 11.9568V4.83694C18.3088 2.16986 16.1389 0 13.4717 0C10.8046 0 8.63477 2.16986 8.63477 4.83694V11.9568C8.63477 14.6239 10.8046 16.7937 13.4717 16.7937ZM10.1336 4.83694C10.1336 2.99633 11.6311 1.49887 13.4717 1.49887C15.3124 1.49887 16.8099 2.99633 16.8099 4.83694V11.9568C16.8099 13.7974 15.3124 15.2948 13.4717 15.2948C11.6311 15.2948 10.1336 13.7974 10.1336 11.9568V4.83694Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default MicrophoneIcon
