import React, { useCallback, useEffect, useRef } from 'react'
import { FormProvider, SubmitHandler, UseFormSetError, useForm } from 'react-hook-form'
import { Emoji } from 'emoji-mart'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useSWRConfig } from 'swr'
import { httpClient } from '../../../../shared/api/http-client'
import Modal from '../../../../shared/components/modal'
import { BadRequest } from '../../../../shared/errors/bad-request'
import { InternalError } from '../../../../shared/errors/internal-error'
import PrimaryButton from 'shared/components/primary-button'
import SaveIcon from 'shared/icons/save-icon'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'
import { usePinnedPosts } from 'modules/community/api/use-pinned-posts'
import PostAttachments from 'modules/community/components/manage-post/components/attachments'
import AddPostContentEditor, {
  EditorRef,
} from 'modules/community/components/manage-post/components/content'
import AddPostInputField from 'modules/community/components/manage-post/components/input-field'
import AddPostTopicSelect from 'modules/community/components/manage-post/components/topic-select'
import { generatePathFromName } from 'modules/community/hooks/path-utils'
import { PostAttachmentType } from 'modules/community/types/post-attachment'
import { escapeAttachmentLocalFields } from 'modules/community/utils/attachments'
import { useCommunityWithTopics } from '../../hooks/use-community-with-topics'
import { PostInterface } from '../../types/post-interface'

interface FormValues {
  title: string
  path: string
  content: string
  topic?: number
  attachments?: PostAttachmentType[]
}

type ErrorFields = Parameters<UseFormSetError<FormValues>>[0]

const errorFields: ErrorFields[] = ['title', 'path', 'topic', 'content', 'root']

export interface UpdatePostProps {
  post: PostInterface
  open: boolean
  onClose: () => void
}

export const PostUpdateModal = ({ post, open, onClose }: UpdatePostProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { topicPath } = router.query
  const { data: community, isValidating } = useCommunityWithTopics()
  const { mutate } = useSWRConfig()
  const { isUploading } = usePostAttachmentsContext()
  const getPinnedPostsQuery = usePinnedPosts({ config: { revalidateOnMount: true } })

  const formMethods = useForm<FormValues>({
    defaultValues: {
      title: post.title,
      path: post.path,
      content: post.content,
      topic: post.topic.id,
      attachments: post.attachments,
    },
  })
  const editorRef = useRef<EditorRef>(null)

  function convertNameToPath(e: React.FocusEvent<HTMLInputElement>) {
    if (!e.target.value) {
      formMethods.setValue('path', generatePathFromName(formMethods.getValues('title')))
    }
  }

  const handleAddEmoji = (emoji: typeof Emoji.Props) => editorRef.current?.insertEmoji(emoji.native)

  const clearForm = useCallback(() => {
    formMethods.reset()
    editorRef.current?.clear()
  }, [])

  const clearErrors = useCallback(() => {
    formMethods.clearErrors()
    editorRef.current?.clearError()
  }, [])

  const setError = useCallback(
    (name: ErrorFields, error?: string) => formMethods.setError(name, { message: error }),
    [],
  )

  const onSubmit: SubmitHandler<FormValues> = async (formData: FormValues) => {
    if (!community) {
      return
    }

    clearErrors()

    const { title, path, content, topic, attachments } = formData
    try {
      const { data } = await httpClient.put<PostInterface>(`/api/community/post/${post.id}`, {
        title,
        path,
        content,
        topic,
        attachments: escapeAttachmentLocalFields(attachments),
      })

      const selectedTopic = community?.topics.find(t => t.id === topic)
      const mutateKey = selectedTopic && selectedTopic.path === topicPath ? 'topic-posts' : 'posts'
      // We don’t receive the postCommentsInfo field in the PUT response. We need to request it from the backend separately.
      const isPinnedPost = getPinnedPostsQuery.data?.find(pinned => pinned.id === post.id)

      isPinnedPost
        ? await getPinnedPostsQuery.mutate(prev =>
            prev?.map(post => (post.id === data.id ? { ...post, ...data } : post)),
          )
        : await mutate<PostInterface[]>(mutateKey, prev =>
            prev?.map(post => (post.id === data.id ? { ...post, ...data } : post)),
          )

      onClose()
      clearForm()
    } catch (e) {
      if (e instanceof BadRequest) {
        errorFields.forEach(name => {
          const error = e as BadRequest
          if (error.errors?.fields?.[name]) {
            setError(name, error.errors.fields[name].join('\n'))
          }

          if (name === 'root' && error.errors?.common) {
            setError(name, error.errors.common.join('\n'))
          }
        })
      } else if (e instanceof InternalError) {
        setError('root', t('core.error.internal_error_message'))
      }
    }
  }

  useEffect(() => {
    if (!open) {
      clearForm()
      clearErrors()
    }
  }, [open, clearForm, clearErrors])

  if (!community || isValidating) {
    return <div className="mb-10 h-28 animate-pulse rounded-lg bg-gray" />
  }

  const commonError = formMethods.formState.errors.root?.message

  return (
    <>
      <Modal opened={open} onClose={onClose} title={t('settings.update_post.modal.title')}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <AddPostInputField name="title" maxLength={256} label={t('home.add_post.title')} />
            <AddPostInputField
              name="path"
              maxLength={32}
              onFocus={convertNameToPath}
              label={t('home.add_post.path')}
            />
            <AddPostTopicSelect />
            <AddPostContentEditor ref={editorRef} content={post.content} mentions={post.mentions} />
            <PostAttachments onAddEmoji={handleAddEmoji} />
            <div className="flex justify-end">
              <PrimaryButton className="ml-auto" type="submit" disabled={isUploading}>
                <SaveIcon fill="currentColor" className="text-white" />
                {t('global.save')}
              </PrimaryButton>
            </div>
            <div>{commonError && <p className="mt-2 text-sm text-red">{commonError}</p>}</div>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default PostUpdateModal
