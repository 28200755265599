import { usePostAttachmentsContext } from 'modules/attachments/components/context'

interface LastPreviewSlideProps {
  index: number
  slidesCount: number
  visibleSlidesCount: number
}

export const LastPreviewSlide: React.FC<LastPreviewSlideProps> = ({
  index,
  slidesCount,
  visibleSlidesCount,
}) => {
  const { setInitialGallerySlide, setIsGalleryVisible } = usePostAttachmentsContext()

  const handleOpenGallery = () => {
    setInitialGallerySlide(index)
    setIsGalleryVisible(true)
  }

  return (
    <button
      onClick={handleOpenGallery}
      className="absolute inset-0 z-[12] flex items-center justify-center rounded-lg bg-black bg-opacity-60 text-3xl font-bold text-white"
    >
      +{slidesCount - visibleSlidesCount}
    </button>
  )
}
