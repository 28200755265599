import { twJoin } from 'tailwind-merge'
import ArrowLeftIcon from '../icons/arrow-left-icon'

export interface CarouselButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  direction: 'left' | 'right'
}

export const CarouselButton: React.FC<CarouselButtonProps> = ({ onClick, direction }) => (
  <div
    className={twJoin(
      'absolute top-1/2 z-30 -translate-y-1/2',
      direction === 'left' ? 'left-2' : 'right-2',
    )}
  >
    <button
      onClick={onClick}
      className="group relative flex h-10 w-10 items-center justify-center rounded-full bg-white shadow-md hover:border-2 hover:border-darkblue2"
    >
      <ArrowLeftIcon
        className={twJoin(
          'stroke-blue-300 group-hover:stroke-darkblue2',
          direction === 'right' && 'rotate-180',
        )}
      />
    </button>
  </div>
)
