import { useRollbar } from '@rollbar/react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { SecondaryButton } from 'shared/components/button'
import { PrimaryButton } from 'shared/components/button'
import useUser, { isDomainOwner, isWsAdmin, isWsAssistant } from 'shared/hooks/use-user'
import CommentOutlinedIcon from 'shared/icons/comment-outlined-icon'
import { timeSince } from 'shared/utils/date-time/time-since'
import { AudioPlayerAttachement } from 'modules/attachments/components/audio-player'
import { CommentsList } from 'modules/comments/components'
import { CommentsTypeEnum } from 'modules/comments/types'
import { TextWithMentions } from 'modules/community/components/editor/utils/deserealizeMentionToHTML'
import LikeButton from 'modules/community/components/like/like-button'
import { PostAuthorInfo } from 'modules/community/components/post-announce/author-info'
import { PostActionsMenu } from 'modules/community/components/post/components/post-actions-menu'
import { PostAttachmentsViewer } from 'modules/community/components/post/components/post-attachments-viewer'
import { PostStatusEnum } from 'modules/community/enums/post-status-enum'
import useIsUserMember from 'modules/community/hooks/use-is-user-member'
import { AttachmentTypeEnum } from 'modules/community/types/post-attachment'
import { PostInterface } from 'modules/community/types/post-interface'

export const PostView = ({
  post,
  handleApprovePost,
  handleDecliePost,
  handleDeletePost,
  handleDeclinePostAndBanUser,
  deletePostAndBanUser,
  handleLikeClick,
  isLoading,
}: {
  post: PostInterface
  handleApprovePost?: () => void
  handleDecliePost?: () => void
  handleDeletePost?: () => Promise<void>
  handleLikeClick?: () => void
  handleDeclinePostAndBanUser?: (removeRecentActivitiesChecked: boolean) => Promise<void>
  deletePostAndBanUser?: (removeRecentActivitiesChecked: boolean) => Promise<void>
  isLoading: boolean
}) => {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const { user, loading: isUserLoading } = useUser()
  const { isUserMember } = useIsUserMember()
  const router = useRouter()
  const { communityPath } = router.query as { communityPath: string }

  const canManagePostStatus =
    post.status !== PostStatusEnum.Approved &&
    user &&
    (isWsAdmin(user) || isDomainOwner(user) || isWsAssistant(user))

  return (
    <>
      <div className="max-w-auto mx-4 pt-6 font-avertaPE sm:mx-6 md:mx-8">
        <div className="mb-5 flex items-center gap-2">
          <PostAuthorInfo author={post.author} userId={post.userId}>
            <div className="bluegray inline lg:block">
              <span className="text-lightgray-100">
                {timeSince(new Date(post.createdAt), {
                  locale: user?.dashboardLocale,
                })}
              </span>{' '}
              <span className="text-lightgray-100">{t('community.pages_on')}</span>{' '}
              <Link
                className="font-semibold text-darkblue"
                href={`/community/${communityPath}/${post.topic.path}`}
                shallow
              >
                #{post.topic.name}
              </Link>
            </div>
          </PostAuthorInfo>

          {canManagePostStatus && handleApprovePost && handleDecliePost && (
            <div className="ml-auto flex gap-3 max-sm:hidden">
              <PrimaryButton onClick={handleApprovePost} isFetching={isUserLoading || isLoading}>
                {t('community.moderation.posts.approve_button.title')}
              </PrimaryButton>
              <SecondaryButton
                className="disabled:border-gray disabled:text-gray"
                onClick={handleDecliePost}
                disabled={post.status === PostStatusEnum.Declined}
                isFetching={isUserLoading || isLoading}
              >
                {t('community.moderation.posts.decline_button.title')}
              </SecondaryButton>
            </div>
          )}
          <PostActionsMenu
            deletePostAndBanUser={
              deletePostAndBanUser
                ? removeRecentActivitiesChecked =>
                    deletePostAndBanUser(removeRecentActivitiesChecked)
                : undefined
            }
            post={post}
            deletePost={handleDeletePost}
            declinePostAndBanUser={handleDeclinePostAndBanUser}
          />
        </div>
        {canManagePostStatus && (
          <div className="mb-2 ml-auto flex gap-3 sm:hidden">
            <PrimaryButton onClick={handleApprovePost} isFetching={isUserLoading || isLoading}>
              {t('community.moderation.posts.approve_button.title')}
            </PrimaryButton>
            <SecondaryButton
              className="disabled:border-gray disabled:text-gray"
              onClick={handleDecliePost}
              disabled={post.status === PostStatusEnum.Declined}
              isFetching={isUserLoading || isLoading}
            >
              {t('community.moderation.posts.decline_button.title')}
            </SecondaryButton>
          </div>
        )}
        <h2 className="community-post-title pb-1 sm:pb-4">{post.title}</h2>
        <TextWithMentions
          className="text-darkblue/90"
          text={post.content}
          mentions={post.mentions}
        />
        {!!post?.attachments?.length &&
          post.attachments.map(
            attachment =>
              attachment.type === AttachmentTypeEnum.Audio && (
                <AudioPlayerAttachement
                  attachment={attachment}
                  height="50px"
                  width="100%"
                  className="mt-3"
                  key={attachment.dataFileId}
                />
              ),
          )}

        <PostAttachmentsViewer attachments={post.attachments || []} />

        {post.status === PostStatusEnum.Approved && !!handleLikeClick && (
          <div className="mt-[22px] flex items-center gap-1">
            <LikeButton
              isLiked={post.isLiked}
              likesCount={post.likesCount}
              handleLikeClick={handleLikeClick}
              postId={post.id}
            />
            <div className="flex items-center gap-2 rounded-md px-1.5 py-2">
              <CommentOutlinedIcon className="h-5 w-5 text-bluegray transition-colors group-hover:text-blue" />
              {post?.commentsCount || ''}
            </div>
          </div>
        )}
      </div>
      <div id="Comments" className="px-4 font-avertaPE sm:px-6 md:px-8">
        <div className="mb-3 mt-4 text-xl font-semibold text-darkblue md:mb-5 md:mt-6">
          {t('post_details.comments')}
        </div>
        {user && (
          <CommentsList
            commentsType={CommentsTypeEnum.Community}
            onError={() => toast.error(t('comments.error.error-loading-comments'))}
            locale={user.dashboardLocale}
            readOnly={!isUserMember}
            rollbar={rollbar}
            community={{
              postId: post.id,
              communityPath: String(communityPath),
            }}
          />
        )}
      </div>
    </>
  )
}
