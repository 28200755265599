import React, { CSSProperties, forwardRef, useEffect, useImperativeHandle } from 'react'
import { useFormContext } from 'react-hook-form'
import { Editable, ReactEditor, Slate } from 'slate-react'
import { isKeyHotkey } from 'is-hotkey'
import { useTranslation } from 'next-i18next'
import { Descendant, Range, Transforms } from 'slate'
import { MentionInterface, Suggestion } from 'modules/comments/components/comment/comment.types'
import { deserializeTextHtml } from 'modules/community/hooks/slate-editor-utils'
import HoveringToolbar from 'modules/post/horizontal-toolbar'
import { MentionSuggestions } from '../../editor/elements/suggestion-mention/suggestion-mention'
import { useEditor } from '../../editor/hooks/use-editor'
import { insertMention } from '../../editor/utils/mention'

const initialContent: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

const editorStyle: CSSProperties = {
  border: 'solid',
  borderColor: '#dce0e9',
  borderRadius: '5px',
  borderWidth: '1px',
  maxHeight: '300px',
  overflowX: 'hidden',
  overflowY: 'scroll',
  padding: '15px',
  minHeight: '200px',
  backgroundColor: 'rgb(249, 251, 252)',
}

export interface EditorRef {
  clear: () => void
  clearError: () => void
  insertEmoji: (emoji: string) => void
}

interface AddPostContentEditorProps {
  content?: string
  mentions?: MentionInterface[]
}

const AddPostContentEditor = forwardRef<EditorRef, AddPostContentEditorProps>(
  function AddPostContentEditor(props, ref) {
    const { t } = useTranslation()

    const { watch, setValue } = useFormContext()

    const {
      editor,
      content,
      serializedTextHtml,
      target,
      suggestions,
      suggestionsLoading,
      contentError,
      insertEmoji,
      setContent,
      setTarget,
      setContentError,
      updateEditorContent,
      renderElement,
      renderLeaf,
    } = useEditor({
      initialContent:
        props.content && props.mentions
          ? ((deserializeTextHtml(props.content, props.mentions) as Descendant[]) ?? initialContent)
          : initialContent,
    })

    useImperativeHandle(ref, () => ({
      clear: () => setContent(initialContent),
      clearError: () => setContentError(''),
      insertEmoji,
    }))

    useEffect(() => {
      setValue('content', serializedTextHtml)
    }, [serializedTextHtml])

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
      const { selection } = editor

      // Default left/right behavior is unit:'character'.
      // This fails to distinguish between two cursor positions, such as
      // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
      // Here we modify the behavior to unit:'offset'.
      // This lets the user step into and out of the inline without stepping over characters.
      // You may wish to customize this further to only use unit:'offset' in specific cases.
      // Reference from official Slate example: https://github.com/ianstormtaylor/slate/blob/6bc57583587d88b26fb03e054511b6d7ac23ef7c/site/examples/ts/inlines.tsx#L74
      if (selection && Range.isCollapsed(selection)) {
        const { nativeEvent } = event
        if (isKeyHotkey('left', nativeEvent)) {
          event.preventDefault()
          Transforms.move(editor, { unit: 'offset', reverse: true })
          return
        }
        if (isKeyHotkey('right', nativeEvent)) {
          event.preventDefault()
          Transforms.move(editor, { unit: 'offset' })
          return
        }
      }
    }

    const handleMentionSelect = (mention: Suggestion) => {
      if (target) {
        insertMention(
          editor,
          {
            displayName: mention.displayName,
            id: mention.userId,
            profileImageUrl: mention.profileImageUrl,
          },
          target,
        )
        setTarget(null)
        ReactEditor.focus(editor)
      }
    }

    return (
      <div className="mb-6">
        <label className="color-text-black font-medium">
          <span>{t('home.add_post.content')}</span>
          <Slate editor={editor} initialValue={content} onChange={updateEditorContent}>
            <HoveringToolbar />
            <Editable
              style={editorStyle}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onKeyDown={onKeyDown}
            />
            {target && (suggestionsLoading || (suggestions && suggestions.length > 0)) && (
              <MentionSuggestions
                target={target}
                editor={editor}
                items={suggestions || []}
                isLoading={suggestionsLoading}
                onSelect={mention => handleMentionSelect(mention)}
              />
            )}
          </Slate>
        </label>
        {contentError && <p className="mt-2 text-sm text-red">{contentError}</p>}
      </div>
    )
  },
)

export default AddPostContentEditor
