import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { PostAttachmentType } from 'modules/community/types/post-attachment'

interface AudioPlayerAttachementInterface {
  attachment: PostAttachmentType
  height: string
  width: string
  className?: string
}

export const AudioPlayerAttachement = ({
  attachment,
  height,
  width,
  className,
}: AudioPlayerAttachementInterface) => {
  return (
    attachment && (
      <div className={className}>
        <ReactPlayer url={attachment.url} controls={true} height={height} width={width} />
      </div>
    )
  )
}
