import { isAuthorizedUser } from '../../../shared/hooks/use-user'
import { UnauthorizedUser, UserInterface } from 'shared/types/user-interface'
import { MemberRoleEnum, MemberWorkspaceRoleEnum } from 'modules/community/enums/member-enum'
import { IsMemberResponse } from 'modules/community/hooks/use-is-user-member'
import { BannedMemberInterface, MemberInterface } from 'modules/community/types/member-interface'
import { PostInterface } from 'modules/community/types/post-interface'
import { determineRole } from './determine-role'

export type ABACRole = 'owner' | 'admin' | 'assistant' | 'member'
type User = UserInterface

type Permissions = {
  posts: {
    dataType: { post: PostInterface; member: IsMemberResponse | undefined }
    action: 'update' | 'delete' | 'ban' | 'decline' | 'pin' | 'unpin'
  }
  pendingPosts: {
    dataType: undefined | null
    action: 'view'
  }
  members: {
    dataType: { member: MemberInterface | BannedMemberInterface }
    action: 'ban' | 'delete' | 'unban'
  }
}

type PermissionCheck<Key extends keyof Permissions> =
  | boolean
  | ((user: User, data: Permissions[Key]['dataType']) => boolean)

type RolesWithPermissions = {
  [R in ABACRole]: Partial<{
    [Key in keyof Permissions]: Partial<{
      [Action in Permissions[Key]['action']]: PermissionCheck<Key>
    }>
  }>
}

const isPostAuthor = (member: IsMemberResponse | undefined, post: PostInterface): boolean =>
  post.userId === member?.userId
const isOwner = (role?: MemberWorkspaceRoleEnum | MemberRoleEnum): boolean =>
  role === MemberWorkspaceRoleEnum.Owner || role === MemberRoleEnum.Owner
const isAdmin = (role?: MemberWorkspaceRoleEnum | MemberRoleEnum): boolean =>
  role === MemberWorkspaceRoleEnum.Admin || role === MemberRoleEnum.Admin
const isAssistant = (role?: MemberWorkspaceRoleEnum | MemberRoleEnum): boolean =>
  role === MemberWorkspaceRoleEnum.Moderator || role === MemberRoleEnum.Moderator

const isSelfMember = (user: UserInterface, member: IsMemberResponse | undefined): boolean =>
  member?.userId === user.id

const ROLES = {
  owner: {
    posts: {
      update: (_, { post, member }) => isPostAuthor(member, post),
      delete: true,
      decline: true,
      pin: true,
      unpin: true,
      ban: (_, { post, member }) => !isPostAuthor(member, post),
    },
    pendingPosts: {
      view: true,
    },
    members: {
      ban: (user, { member }) => !isSelfMember(user, member),
      delete: (user, { member }) => !isSelfMember(user, member),
      unban: true,
    },
  },
  admin: {
    posts: {
      update: (_, { post, member }) => isPostAuthor(member, post),
      // A post can only be deleted by the author or any of the moderation roles. Any of the moderation roles can delete any post
      delete: true,
      decline: true,
      pin: true,
      unpin: true,
      // Not author specified to prevent the user from banning themselves
      ban: (_, { post, member }) =>
        !isPostAuthor(member, post) &&
        !isOwner(post.author?.workspaceRole) &&
        !isAdmin(post.author?.workspaceRole),
    },
    pendingPosts: {
      view: true,
    },
    members: {
      ban: (user, { member }) =>
        !isSelfMember(user, member) && !isOwner(member.role) && !isAdmin(member.role),
      delete: (user, { member }) =>
        !isSelfMember(user, member) && !isOwner(member.role) && !isAdmin(member.role),
      unban: true,
    },
  },
  assistant: {
    posts: {
      update: (_, { post, member }) => isPostAuthor(member, post),
      delete: true,
      decline: true,
      pin: true,
      unpin: true,
      ban: (_, { post, member }) =>
        !isPostAuthor(member, post) &&
        !isOwner(post.author?.workspaceRole) &&
        !isAdmin(post.author?.workspaceRole) &&
        !isAssistant(post.author?.workspaceRole),
    },
    pendingPosts: {
      view: true,
    },
    members: {
      ban: (user, { member }) =>
        !isSelfMember(user, member) &&
        !isOwner(member.role) &&
        !isAdmin(member.role) &&
        !isAssistant(member.role),
      delete: (user, { member }) =>
        !isSelfMember(user, member) &&
        !isOwner(member.role) &&
        !isAdmin(member.role) &&
        !isAssistant(member.role),
      unban: true,
    },
  },
  member: {
    posts: {
      update: (_, { post, member }) => isPostAuthor(member, post),
      delete: (_, { post, member }) => isPostAuthor(member, post),
    },
  },
} as const satisfies RolesWithPermissions

/**
 * Checks if a user has permission to perform a specified action on a given resource.
 *
 * @example
 * const canDelete = hasPermission(currentUser, 'posts', 'delete', { post, member });
 */
export function hasPermission<Resource extends keyof Permissions>(
  user: User | UnauthorizedUser | undefined,
  resource: Resource,
  action: Permissions[Resource]['action'],
  data: Permissions[Resource]['dataType'],
): boolean {
  if (!user || !isAuthorizedUser(user)) {
    return false
  }

  const role = determineRole(user)

  const permission = (ROLES as RolesWithPermissions)[role][resource]?.[action]
  if (permission == null) return false

  if (typeof permission === 'boolean') return permission
  return data != null && permission(user, data)
}

/**
 * Checks multiple actions for a given resource and returns a tuple of boolean results.
 *
 * @example
 * const [canBan, canDelete] = hasPermissions(currentUser, 'members', ['ban', 'delete'], { member });
 */
export function hasPermissions<
  Resource extends keyof Permissions,
  T extends Permissions[Resource]['action'],
  Actions extends readonly T[],
>(
  user: User | UnauthorizedUser | undefined,
  resource: Resource,
  actions: Actions,
  data: Permissions[Resource]['dataType'],
): { [K in keyof Actions]: boolean } {
  return actions.map(action => hasPermission(user, resource, action, data)) as {
    [K in keyof Actions]: boolean
  }
}
