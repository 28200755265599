import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import Confirmation from 'shared/components/confirmation-modal'
import useUser from 'shared/hooks/use-user'
import CloseIcon from 'shared/icons/close-icon'
import PenOutlineIcon from 'shared/icons/pen-outline-icon'
import ThreeDotsHorizontalIcon from 'shared/icons/three-dots-horizontal-icon'
import TrashOutlineIcon from 'shared/icons/trash-outline-icon'
import { handleWithoutPropagating } from 'shared/utils/handle-without-propagating'
import { PostUpdateModal } from 'modules/community/components/manage-post/update-post'
import useIsUserMember from 'modules/community/hooks/use-is-user-member'
import { PostInterface } from 'modules/community/types/post-interface'
import { hasPermissions } from 'modules/community/utils/abac-roles-permissions'
import { RemoveRecentActivitiesCheckbox } from 'modules/post/remove-recent-activities-checkbox'

interface PostActionsMenuProps {
  post: PostInterface
  deletePost?: () => Promise<void>
  deletePostAndBanUser?: (removeRecentActivitiesChecked: boolean) => Promise<void>
  declinePostAndBanUser?: (removeRecentActivitiesChecked: boolean) => Promise<void>
  className?: string
}

export const PostActionsMenu = ({
  post,
  deletePost,
  deletePostAndBanUser,
  declinePostAndBanUser,
  className,
}: PostActionsMenuProps) => {
  const { user } = useUser()
  const { member } = useIsUserMember()
  const { t } = useTranslation()
  const [isRemovePostOpened, setIsRemovePostOpened] = useState(false)
  const [isRemovePostAndBanUserOpened, setIsRemovePostAndBanUserOpened] = useState(false)
  const [removeRecentActivitiesChecked, setRemoveRecentActivitiesChecked] = useState(false)
  const [isDeclinePostAndBanUserOpened, setIsDeclinePostAndBanUserOpened] = useState(false)
  const [isOpenPostUpdate, setIsOpenPostUpdate] = useState(false)

  const [isPostUpdatable, isPostDeletable, isPostAuthorBannable, isPostDeclinable] = hasPermissions(
    user,
    'posts',
    ['update', 'delete', 'ban', 'decline'],
    {
      post,
      member,
    },
  )
  
  const isPostDeclinableAndAuthorBannable = isPostDeclinable && isPostAuthorBannable

  const hasAnyPermission =
    isPostUpdatable || isPostDeletable || isPostAuthorBannable || isPostDeclinableAndAuthorBannable

  const resetRemovablePost = () => {
    setIsRemovePostOpened(false)
  }

  const resetRemovePostAndBanUser = () => {
    setIsRemovePostAndBanUserOpened(false)
    setRemoveRecentActivitiesChecked(false)
  }

  const resetDeclinePostAndBanUser = () => {
    setIsDeclinePostAndBanUserOpened(false)
    setRemoveRecentActivitiesChecked(false)
  }
  const removePost = () => {
    deletePost?.().then(() => {
      setIsRemovePostOpened(false)
      toast.success(t('community.actions.remove_post.success'))
    })
  }

  const removePostAndBanUser = async () => {
    await deletePostAndBanUser?.(removeRecentActivitiesChecked).then(() =>
      toast.success(t('community.actions.remove_post_and_ban_user.success')),
    )
    setIsRemovePostAndBanUserOpened(false)
  }

  const handleDeclinePostAndBanUser = async () => {
    await declinePostAndBanUser?.(removeRecentActivitiesChecked).then(() =>
      toast.success(t('community.actions.decline_post_and_ban_user.success')),
    )
    setIsDeclinePostAndBanUserOpened(false)
  }

  if (!hasAnyPermission) {
    return null
  }

  return (
    <>
      <Menu
        onClick={handleWithoutPropagating()}
        as="div"
        className="relative mb-[5px] ml-auto mt-[5px] flex h-[30px] w-[30px] items-center"
      >
        <MenuButton onClick={handleWithoutPropagating()}>
          <ThreeDotsHorizontalIcon className="h-8 w-8 rounded-full text-bluegray transition-colors duration-300 hover:bg-[#E6E6E6]" />
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems
            as="ul"
            anchor="bottom end"
            className={twMerge(
              'z-10 flex w-max min-w-[144px] max-w-sm flex-col rounded-[10px] bg-white px-5 py-3 shadow-[0px_4px_10px_0px_#00000040]',
              className,
            )}
          >
            {isPostDeletable && deletePost && (
              <MenuItem as="li">
                {({ focus }) => (
                  <button
                    className={`${
                      focus ? 'text-darkblue/70' : 'text-darkblue'
                    } group flex w-full items-center gap-1.5 rounded-md py-1.5 text-sm`}
                    onClick={() => setIsRemovePostOpened(true)}
                  >
                    <CloseIcon className="h-5 w-5 fill-darkblue group-hover:fill-darkblue/70" />
                    {t('community.actions.remove_post.label')}
                  </button>
                )}
              </MenuItem>
            )}
            {isPostAuthorBannable && deletePostAndBanUser && (
              <MenuItem as="li">
                {({ focus }) => (
                  <button
                    className={`${
                      focus ? 'text-darkblue/70' : 'text-darkblue'
                    } group flex w-full items-center gap-1.5 rounded-md py-1.5 text-sm`}
                    onClick={() => setIsRemovePostAndBanUserOpened(true)}
                  >
                    <TrashOutlineIcon className="h-5 w-5" />
                    {t('community.actions.delete_and_ban_user.label')}
                  </button>
                )}
              </MenuItem>
            )}
            {isPostDeclinableAndAuthorBannable && declinePostAndBanUser && (
              <MenuItem as="li">
                {({ focus }) => (
                  <button
                    className={`flex w-full items-center gap-1.5 rounded-md py-1.5 text-sm text-darkblue focus:text-darkblue/70 ${
                      focus ? 'text-darkblue/70' : 'text-darkblue'
                    } group flex w-full items-center gap-1.5 rounded-md py-1.5 text-sm`}
                    onClick={() => setIsDeclinePostAndBanUserOpened(true)}
                  >
                    <TrashOutlineIcon className="h-5 w-5" />
                    {t('community.actions.decline_and_ban_user.label')}
                  </button>
                )}
              </MenuItem>
            )}
            {isPostUpdatable && (
              <MenuItem as="li">
                <button
                  className="flex w-full items-center gap-1.5 rounded-md py-1.5 text-sm text-darkblue focus:text-darkblue/70"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setIsOpenPostUpdate(true)
                  }}
                >
                  <PenOutlineIcon className="h-5 w-5" />
                  {t('community.actions.update.label')}
                </button>
              </MenuItem>
            )}
          </MenuItems>
        </Transition>
      </Menu>
      {isRemovePostOpened && (
        <Confirmation
          onConfirm={removePost}
          onCloseModal={resetRemovablePost}
          confirmationContent={t('settings.form.delete_post_confirmation.label')}
        />
      )}
      {isRemovePostAndBanUserOpened && (
        <Confirmation
          onConfirm={removePostAndBanUser}
          onCloseModal={resetRemovePostAndBanUser}
          confirmationContent={
            <RemoveRecentActivitiesCheckbox
              label={t('settings.form.delete_post_and_ban_user_confirmation.label')}
              memberDisplayName={post.author?.displayName}
              checked={removeRecentActivitiesChecked}
              onCheck={() => setRemoveRecentActivitiesChecked(prev => !prev)}
            />
          }
          errorMessageMap={{
            unprocessableContentError: 'community.error.error_user_already_banned',
          }}
        />
      )}
      {isDeclinePostAndBanUserOpened && (
        <Confirmation
          onConfirm={handleDeclinePostAndBanUser}
          onCloseModal={resetDeclinePostAndBanUser}
          confirmationContent={
            <RemoveRecentActivitiesCheckbox
              label={t('settings.form.decline_post_and_ban_user_confirmation.label')}
              memberDisplayName={post.author?.displayName}
              checked={removeRecentActivitiesChecked}
              onCheck={() => setRemoveRecentActivitiesChecked(prev => !prev)}
            />
          }
          errorMessageMap={{
            unprocessableContentError: 'community.error.error_user_already_banned',
          }}
        />
      )}
      <PostUpdateModal
        post={post}
        open={isOpenPostUpdate}
        onClose={() => setIsOpenPostUpdate(false)}
      />
    </>
  )
}
