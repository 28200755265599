import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Emoji } from 'emoji-mart'
import { AudioAttachement } from 'modules/attachments/components/audio-attachement'
import { PostAttachmentsProvider } from 'modules/attachments/components/context'
import EmbeddedVideoAttachment from 'modules/attachments/components/embedded-video-attachment'
import { GifAttachment } from 'modules/attachments/components/gif-attachment'
import AttachmentsPreview from 'modules/attachments/components/preview'
import UploadAttachment from 'modules/attachments/components/upload-attachment'
import { EmojiPicker } from 'modules/comments/components/comment-editor/elements/emoji-picker/emoji-picker'
import { PostAttachmentType } from 'modules/community/types/post-attachment'

interface PostAttachmentsProps {
  onAddEmoji: (emoji: typeof Emoji.Props) => void
}

export default function PostAttachments({ onAddEmoji }: PostAttachmentsProps) {
  const { setValue, control } = useFormContext()

  const handleChange = useCallback(
    (attachments: PostAttachmentType[]) => setValue('attachments', attachments),
    [],
  )

  const handleAddEmoji = (emoji: typeof Emoji.Props) => {
    onAddEmoji(emoji)
  }

  return (
    <Controller
      control={control}
      name="attachments"
      render={({ field }) => (
        <PostAttachmentsProvider isEditable onChange={handleChange} attachments={field.value}>
          <div className="flex flex-col gap-4">
            <AttachmentsPreview />
            <div className="mb-6 flex items-center gap-6">
              <EmojiPicker onEmojiSelect={handleAddEmoji} iconClassName="w-6 h-6 text-darkblue" />
              <UploadAttachment />
              <EmbeddedVideoAttachment iconClassName="text-darkblue" />
              <GifAttachment iconClassName="text-darkblue" />
              <AudioAttachement />
            </div>
          </div>
        </PostAttachmentsProvider>
      )}
    />
  )
}
